<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="raceTypesData" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { MessageBox, Message } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import typeRacesSchemas from './type-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter
    },
    computed: {
      raceTypesData() {
        let rows = this.$store.state.raceTypes;
        return dtHelper.filterByRules(rows, this.filterOutput);
      }
    },
    data() {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(typeRacesSchemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          },
        ],
        filter: {},
        rules: dtHelper.buildRules(typeRacesSchemas)
      }
    },
    mounted() {
      this.$store.dispatch('fetchRaceTypes');
      this.$store.dispatch('setPageTitle', 'Quản lý kiểu cuộc đua');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.create
      }])
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },
      edit(index, row){
        this.$router.push('/race-type/' + row.id);
      },
      create(){
        this.$router.push('/race-type/create');
      },
      remove(index, row){
        MessageBox.confirm('Bạn có chắc chắn xóa không?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeRaceTypes', row.id).then(res => {
            if (res.success) {
              Message({
                message: 'Xóa thành công',
                type: 'success'
              });
            }
          })
        }).catch(() => {
          Message({
            type: 'info',
            message: 'Hủy bỏ'
          });
        });
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
